import Vue from 'vue'
import App from './App.vue'
import router from './router/router.js'
import store from './store/store.js'
import './plugins/apexchart'
import './style/app.scss'
import './style/variables.scss'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import lang from 'element-ui/lib/locale/lang/es';
import locale from 'element-ui/lib/locale';
import session from './methods/session_storage';

locale.use(lang);

Vue.use(ElementUI);

Vue.config.productionTip = false

// Global loading instance that will be controlled by the store
let globalLoadingInstance = null;

new Vue({
  router,
  store,
  beforeCreate() { 
    // Only show loading spinner and initialize store if user is authenticated
    if (session.isAuthenticated()) {
      // Show loading spinner
      globalLoadingInstance = this.$loading({
        lock: true,
        text: 'Cargando datos',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      
      // Initialize store
      this.$store.dispatch('INITIALIZE_STORE')
        .catch(error => {
          console.error('Error during store initialization:', error);
          // Show error notification
          this.$notify.error({
            title: 'Error',
            message: 'Error al cargar algunos datos. La aplicación puede no funcionar correctamente.',
            duration: 10000
          });
        });
    }
  },
  mounted() {
    // Watch for changes in the loading state, but only show spinner if authenticated
    this.$store.watch(
      (state, getters) => getters.isAppLoading,
      (isLoading) => {
        if (!isLoading && globalLoadingInstance) {
          globalLoadingInstance.close();
          globalLoadingInstance = null;
        } else if (isLoading && !globalLoadingInstance && session.isAuthenticated()) {
          globalLoadingInstance = this.$loading({
            lock: true,
            text: 'Cargando datos',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
        }
      }
    );
  },
  render: h => h(App),
}).$mount('#app')
