/* eslint-disable */
import config from '../config';
import session from '@/methods/session_storage'


export const makeClient = ({ baseUrl = '' }) => {
  const getHeaders = () => ({
    Accept: 'application/json',
    Authorization: `access_token ${session.getToken()}`,
  });

  const request = (url = '', options = {}) => {
    const completeUrl = new URL(baseUrl + url);
    if (options.params) {
      completeUrl.search = new URLSearchParams(options.params).toString();
    }

    return fetch(completeUrl.toString(), {
      ...options,
      headers: {
        ...getHeaders(),
        ...options.headers,
      },
      body: JSON.stringify(options.body),
      mode: 'cors'
    })
    .then((response) => response.json()
      .then(content => ({ status: response.status, content }))
      .catch(error => {
        console.error('Error parsing JSON response:', error);
        return { status: response.status, content: {} };
      })
    )
    .catch(error => {
      console.error('Network error:', error);
      // Return a resolved promise with an error status to prevent Promise.all from failing
      return { status: 0, content: {}, error: error.message };
    });
  };

  const get = (url, queryParams = {}, getHeaders = {}) => request(url, { method: 'GET', params: queryParams, headers: getHeaders });

  const post = (url, data, postHeaders = {}) => request(url, { method: 'POST', body: data, headers: postHeaders });

  const put = (url, data, putHeaders = {}) => request(url, { method: 'PUT', body: data, headers: putHeaders });

  const remove = (url, deleteHeaders = {}) => request(url, { method: 'DELETE', headers: deleteHeaders });


  return {
    getHeaders, get, post, put, remove,
  };
};

export default makeClient({ baseUrl: config.baseUrl });
