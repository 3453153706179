<template>
  <router-view />
</template>

<script>
import { mapGetters } from 'vuex';
import session from './methods/session_storage';

export default {
  name: "App",
  computed: {
    ...mapGetters({
      isLoading: 'isAppLoading'
    })
  },
  watch: {
    // Watch for route changes to handle initialization after login
    '$route'(to) {
      // If navigating to sign-in page, reset loading state
      if (to.path === '/sign-in') {
        // Reset loading state when navigating to sign-in
        this.$store.commit('SET_LOADING', false);
        this.isInitialized = false;
        return;
      }
      
      // If navigating to home page and user is authenticated, ensure store is initialized
      if (to.path === '/' && session.isAuthenticated()) {
        // Check if we need to initialize (e.g., after login)
        if (!this.isInitialized) {
          this.$store.dispatch('INITIALIZE_STORE');
          this.isInitialized = true;
        }
      }
    }
  },
  data() {
    return {
      isInitialized: false
    };
  },
  created() {
    // Set initial state based on authentication and current route
    this.isInitialized = session.isAuthenticated() && this.$route.path !== '/sign-in';
    
    // Reset loading state if on sign-in page
    if (this.$route.path === '/sign-in') {
      this.$store.commit('SET_LOADING', false);
    }
  }
};
</script>

<style>
#app {
  font-family: Raleway, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fdfeff;
  margin-top: 60px;
  background: #1c222e !important;
}
</style>
